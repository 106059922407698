import Item from "./Item";
import './Transaction.css'
import React from "react";

const Transaction = (props) =>{
  const {items} = props;
    return (
      <div>
      <ul className="item-list">
          {items.map((element) => {
            //normal return <Item title={element.title} amount={element.amount} />
            // space operator
            return <Item {...element} key = {element.id} />
          })}
        </ul>
        </div>
    );
  }

  export default Transaction;